import { useState, useEffect, useRef } from 'react'
import { Grid, Cell } from 'react-foundation'
// import logo from './351studios.svg.svg';
import './styles/App.scss'
import {preloader} from './preloader';
import Menu from './menu';
import WebglShape from './webglshape'

function App() {
  const [loaded, setLoaded] = useState(false)
  const [imagesLoaded, setImagesLoaded] = useState(false)
  const [mobilePrev, setMobilePrev] = useState([])

  const menuEl = document.querySelector('.menu');

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      preloadImages();
    }
  }, [loaded])


  useEffect(() => {
    if (imagesLoaded) {
      new Menu(menuEl);
      setMenuFalse()
    } 
  }, [imagesLoaded])

  // useEffect(() => {
  //   console.log(mobilePrev, 'mobilePrev changed')
  // }, [mobilePrev])

  const preloadImages = () => {
    preloader('.menu__item').then(() => {
      console.log('preloaded');
      setImagesLoaded(true)
    })
  }

  const setMenuFalse = () => {
    let menuItems = document.querySelectorAll('.menu__item');
    let menuArr = [];
    [...menuItems].forEach((item, pos) => {
      menuArr.push(false);
    })
    setMobilePrev(menuArr)
  }

  const getParentLink = (target) => {
    let el = target.parentElement

    if (el.nodeName !== 'A' || typeof(el) === "undefined" || el.nodeName == 'SPAN') {
      el = getParentLink(el)
    } 
    return el
  }

  const linkHandler = (e) => {
    if ("ontouchstart" in document.documentElement) {
      e.preventDefault();
      let el = e.target
      
      if (el.nodeName !== 'A' || typeof(el) === "undefined" || el.nodeName == 'SPAN') {
        el = getParentLink(e.target);
      }

      // console.log(el.nodeName, 'nodeName')
      const index = [...el.parentElement.children].indexOf(el)

      // setMenuFalse()

      if (mobilePrev[index - 1] == false) {
        // setMobilePrev(true)
        // mobilePrev[index - 1] = true
        let items = [...mobilePrev];
        let item = {...items[index - 1]};
        item = true
        items.forEach((item, pos) => {
          items[pos] = false
        })
        items[index - 1] = item
        setMobilePrev(items)
      } else {
        setMenuFalse()
        window.open(el.href, '_blank').focus();        
        // window.location.assign(e.target.href) 
      }
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <video className="App-header__video" autoPlay loop muted playsInline src="https://player.vimeo.com/external/371068079.hd.mp4?s=d9f7c61877e150f97274e2264a0e97fe43c01b3a&profile_id=175" type="video/mp4"></video>
        <img src={'351studios.svg'} className="App-logo" alt="logo" />
      </header>

      <div className="section section-intro" style={{backgroundImage:`url('351header.jpg')`}}>
      </div>
      <div className="section section-about">
        <div className="grid-container">
          <Grid className="grid-x grid-padding-x section-about__inner">
              <Cell small={12}>
                <div className="section-about__content">
                  <a href="https://etherscan.io/address/0xc487d3ffe277d07f5bfb3a01b8a2e0db8a75d3d6" target="_blank" className="section-about__ens" style={{backgroundImage:`url('ens.svg')`}}></a>
                  <p className="section-about__text"><span>351 Studios</span> is a full-service digital agency based in Brooklyn, NY. We bring brands to market in the fields of Web3 and sustainability.</p>              
                  <div className="section-about__video-wrapper">        
                    <video className="section-about__video" autoPlay loop muted playsInline src="https://player.vimeo.com/progressive_redirect/playback/721789545/rendition/720p/file.mp4?loc=external&signature=b674fef38c63411cc16badc77c42d6a6d5f26a592ecc8542baa6cac027e514c2" type="video/mp4"></video>
                  </div>
                </div>
              </Cell>
          </Grid>
        </div>
      </div>
      <section className="section-list">
        <nav className="menu">
          <h2 className="section-list__heading">Our Work</h2>  
          <a href="https://nftnow.com/nft100" target="_blank" className="menu__item menu__item--nft100" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">NFT 100</span>
              </span>
              <span className="menu__item-sub">Event Branding, Graphics & Website</span>
          </a>
          <a href="https://fewoworld.io" target="_blank" className="menu__item menu__item--fewoworld" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">FewoWorld</span>
              </span>
              <span className="menu__item-sub">Website Design & Dev</span>
          </a>
          <a href="https://unworldoceansday.org" target="_blank" className="menu__item menu__item--unwod" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">UN WOD</span>
              </span>
              <span className="menu__item-sub">Event Branding, Graphics & Website</span>
          </a>
          <a href="https://seedclub.xyz" target="_blank" className="menu__item menu__item--seedclub" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">SeedClub</span>
              </span>
              <span className="menu__item-sub">Website Design & Dev</span>
          </a>
          <a href="https://oceanic.global" target="_blank" className="menu__item menu__item--oceanic" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">Oceanic</span>
              </span>
              <span className="menu__item-sub">Branding, Graphics & Website</span>
          </a>
          <a href="https://findingsinthefuture.live" target="_blank" className="menu__item menu__item--findings" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">Findings</span>
              </span>
              <span className="menu__item-sub">Branding, Website Design & Dev</span>
          </a>                
          <a href="https://over-view.com" target="_blank" className="menu__item menu__item--overview" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">Overview</span>
              </span>
              <span className="menu__item-sub">Branding, Website Design & Dev</span>
          </a> 
          <a href="https://fewocious.com" target="_blank" className="menu__item menu__item--fewocious" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">FEWOCiOUS</span>
              </span>
              <span className="menu__item-sub">Website Design & Dev</span>
          </a>
          <a href="https://nftnow.com" target="_blank" className="menu__item menu__item--nftnow" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">nft now</span>
              </span>
              <span className="menu__item-sub">Branding, Website Design & Dev</span>
          </a>
          <a href="https://olliechanin.xyz" target="_blank" className="menu__item menu__item--rooms" onClick={linkHandler}>
              <span className="menu__item-text">
                  <span className="menu__item-textinner">ROOMS</span>
              </span>
              <span className="menu__item-sub">Website Design & Dev</span>
          </a>
        </nav>  
      </section>   
      <section className="section-contact">
        <div className="section-contact__info">
          <h2 className="section-contact__heading">Reach Out</h2>
          <a href="mailto:info@351studios.com" className="button">Contact Us</a>
        </div>
        <WebglShape/>
      </section>              
    </div>
  );
}

export default App;
