import React from 'react'

let webAnimation = () => {
  if(typeof document !== "undefined") {
    var circle = document.getElementById('c');
    if (circle !== null){
      var round = Math.round, min = Math.min,
        PI = Math.PI, sin = Math.sin, cos = Math.cos,

        N_CIRCLES = 8,
        K = 1.15,
        R_CIRCLE = min(80, round(1000/(K/sin(PI/N_CIRCLES) + 1)/2) - 1),
        R_POS = round(K*R_CIRCLE/sin(PI/N_CIRCLES)),

        NS_URI = 'http://www.w3.org/2000/svg',
        XLink_NS = 'http://www.w3.org/1999/xlink',

        dl = document.getElementById('nc'),
        nc_slider_el = document.getElementById('nc-slider'),
        wrap_circle_el = document.querySelector('.circles'),
        wrap_line_el = document.querySelector('.lines'),
        angle_start = 0, angle_incr = PI/90, angle_diff,
        circles, lines, n_lines,
        req_ID = null,
        c = circle;

      Node.prototype.setAttrs = function(attr_obj) {
        for(var prop in attr_obj) {
          this.setAttribute(prop, attr_obj[prop]);
        }
      };

      var prep = function() {
        var frag_list = document.createDocumentFragment(),
            opt;
        if (nc_slider_el){
          for(var i = nc_slider_el.min; i <= nc_slider_el.max; i++) {
            opt = document.createElement('option');
              opt.setAttribute('label', i);
              opt.innerHTML = i;
              frag_list.appendChild(opt);
          }

          while(dl.firstChild) {
            dl.removeChild(dl.firstChild);
          }

          dl.appendChild(frag_list);
        }
      };

      var init = function() {
        var frag_circles = document.createDocumentFragment(),
            frag_lines = document.createDocumentFragment(),
            angle_base,
            angle_base_rad,
            curr_circle_el, angle_curr, angle_curr_rad,
            curr_line_el, x, y;

        if(req_ID !== null) {
          cancelAnimationFrame(req_ID);
          req_ID = null;
        }

        circles = [];
        lines = [];

        N_CIRCLES = (1 == 2) ? nc_slider_el.value : 5;
        R_CIRCLE = min(80, round(1000/(K/sin(PI/N_CIRCLES) + 1)/2) - 1);
        R_POS = round(K*R_CIRCLE/sin(PI/N_CIRCLES));

        angle_base = 360/N_CIRCLES;
        angle_base_rad = 2*PI/N_CIRCLES;
        c = document.getElementById('c');
        c.setAttribute('r', R_CIRCLE);
        angle_diff = 2*angle_base_rad;

        for(var i = 0; i < N_CIRCLES; i++) {

          angle_curr = round(i*angle_base);

          angle_curr_rad = i*angle_base_rad;
          x = R_POS*cos(angle_curr_rad);
          y = R_POS*sin(angle_curr_rad);

          curr_circle_el = document.createElementNS(NS_URI, 'use');
          curr_circle_el.setAttributeNS(XLink_NS, 'xlink:href', '#c');
          curr_circle_el.setAttribute(
            'transform',
            'rotate(' + angle_curr + ') translate(' + R_POS + ')'
          );

          frag_circles.appendChild(curr_circle_el);
          circles.push({
            'el': curr_circle_el,
            'x': x,
            'y': y,
            'a': i*angle_diff
          });

          for(var j = i + 1; j < N_CIRCLES; j++) {
            curr_line_el = document.createElementNS(NS_URI, 'line');
            frag_lines.appendChild(curr_line_el);

            lines.push({
              'el': curr_line_el,
              'idx_start': i,
              'idx_end': j
            });
          }
        }

        n_lines = lines.length;

        while(wrap_circle_el.firstChild) {
          wrap_circle_el.removeChild(wrap_circle_el.firstChild);
        }

        while(wrap_line_el.firstChild) {
          wrap_line_el.removeChild(wrap_line_el.firstChild);
        }

        wrap_circle_el.appendChild(frag_circles);
        wrap_line_el.appendChild(frag_lines);

        ani();
      };

      var ani = function() {
        var x1, y1, x2, y2, angle;

        for(var i = 0; i < n_lines; i++) {
          angle = angle_start + circles[lines[i].idx_start].a;
          x1 = round(circles[lines[i].idx_start].x + R_CIRCLE*cos(angle));
          y1 = round(circles[lines[i].idx_start].y + R_CIRCLE*sin(angle));

          angle = angle_start + circles[lines[i].idx_end].a;
          x2 = round(circles[lines[i].idx_end].x + R_CIRCLE*cos(angle));
          y2 = round(circles[lines[i].idx_end].y + R_CIRCLE*sin(angle));

          lines[i].el.setAttrs({'x1': x1, 'y1': y1, 'x2': x2, 'y2': y2});
        }

        angle_start -= angle_incr;

        req_ID = requestAnimationFrame(ani);
      };

      prep();
      init();
      //test 

      nc_slider_el.addEventListener('change', init, false);
    } else {
      setTimeout(()=>{
        webAnimation();
      }, 500)
    }
  }
}

class WebglShape extends React.Component{

  constructor (props) {
    super(props);
    // this.state = {
    //   transform: ''
    // }    
  }

  componentDidMount () {
    webAnimation();    
  }

  render (){
    
    return (
      <div>
        <svg viewBox="-500 -500 1000 1000">
          <defs><circle id="c"/></defs>
          <g className="circles"></g>
          <g className="lines"></g>
        </svg>
        <div className="controls" style={{display:'none'}}>
          <label htmlFor="nc-slider">Number of circles:</label>
          <input type="range" min="2" max="32" step="1" value="8" list="nc" id="nc-slider"/>
          <datalist id="nc"></datalist>
        </div>
      </div>
    )
    
  }
}

export default WebglShape
